<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div v-if="type === null || type === undefined">
        <div class="d-flex align-center justify-center mt-4">
          <v-btn color="primary" class="bottom--button" @click="chooseType('new')">
            {{ $t('client.btn.create') }}
          </v-btn>
        </div>
        <div class="d-flex align-center justify-center mt-4">
          <v-btn color="primary" class="bottom--button" @click="chooseType('old')">
            {{ $t('client.btn.useExisting') }}
          </v-btn>
        </div>
      </div>
      <div class="modal--form-container" v-else>
        <form @submit.prevent="submit">
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedClient') }"
            v-if="type === 'old'"
          >
            <label class="col-12 col-lg-4">Pilih Klien</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedClient"
                id="selectedClientField"
                v-model="selectedClient"
                :options="clients"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :internal-search="false"
                :loading="clientLoading"
                @search-change="debounceGetClients"
              />
              <span class="val-error">{{ validation.firstError('selectedClient') }}</span>
            </div>
          </div>
          <hr class="my-4" v-if="type === 'old'" />
          <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
            <label class="col-12 col-lg-4">Nama Klien</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="name"
                v-if="type === 'new'"
              />
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedClient != null ? selectedClient.name : ''"
                readonly
                v-if="type === 'old'"
              />
              <span class="val-error">{{ validation.firstError('name') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('phone') }">
            <label class="col-12 col-lg-4">Nomor Telepon Klien</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="phone"
                v-if="type === 'new'"
              />
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedClient != null ? selectedClient.phone_number : ''"
                readonly
                v-if="type === 'old'"
              />
              <span class="val-error">{{ validation.firstError('phone') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('email') }">
            <label class="col-12 col-lg-4">Email Klien</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                v-model="email"
                v-if="type === 'new'"
              />
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedClient != null ? selectedClient.email : ''"
                readonly
                v-if="type === 'old'"
              />
              <span class="val-error">{{ validation.firstError('email') }}</span>
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Catatan</label>
            <div class="col-12 col-lg-8">
              <v-textarea
                type="text"
                outlined
                hide-details
                v-model="notes"
                v-if="type === 'new'"
                rows="5"
              />
              <v-textarea
                type="text"
                outlined
                hide-details
                :value="selectedClient != null ? selectedClient.notes : ''"
                readonly
                v-if="type === 'old'"
                rows="5"
              />
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedBank') }"
            v-if="type === 'new'"
          >
            <label class="col-12 col-lg-4">Nama Bank</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedBank"
                id="selectedBankField"
                v-model="selectedBank"
                :options="banks"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
              />
              <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountNumber') }"
            v-if="type === 'new'"
          >
            <label class="col-12 col-lg-4">Nomor Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="accountNumber" />
              <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('accountHolder') }"
            v-if="type === 'new'"
          >
            <label class="col-12 col-lg-4">Nama Pemilik Rekening</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="accountHolder" />
              <span class="val-error">{{ validation.firstError('accountHolder') }}</span>
            </div>
          </div>
          <div class="d-flex align-center justify-center mt-4">
            <v-btn color="primary" class="bottom--button" type="submit">
              {{ type === 'old' ? $t('client.btn.assign') : $t('client.btn.create') }}
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import LodashMixin from '@/mixins/lodash';

export default {
  name: 'modal-client',
  mixins: [HelperMixin, LodashMixin],
  components: { Multiselect },
  data() {
    return {
      modalName: 'modal-client',
      type: null,
      selectedClient: null,
      commitString: null,
      dispatchString: null,
      dispatchAfterString: null,
      debounceGetClients: null,
    };
  },

  created() {
    this.debounceGetClients = this.debounce(this.getClients, 700);
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      clientLoading: (state) => state.client.loading,
      banks: (state) => state.client.form.banks,
    }),
    name: {
      get() {
        return this.$store.state.client.form.name;
      },
      set(value) {
        this.$store.commit('client/form/SET_NAME', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.client.form.phone;
      },
      set(value) {
        this.$store.commit('client/form/SET_PHONE', value);
      },
    },
    email: {
      get() {
        return this.$store.state.client.form.email;
      },
      set(value) {
        this.$store.commit('client/form/SET_EMAIL', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.client.form.notes;
      },
      set(value) {
        this.$store.commit('client/form/SET_NOTES', value);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.client.form.selectedBank;
      },
      set(value) {
        this.$store.commit('client/form/SET_SELECTED_BANK', value);
      },
    },
    accountHolder: {
      get() {
        return this.$store.state.client.form.accountHolder;
      },
      set(value) {
        this.$store.commit('client/form/SET_ACCOUNT_HOLDER', value);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.client.form.accountNumber;
      },
      set(value) {
        this.$store.commit('client/form/SET_ACCOUNT_NUMBER', value);
      },
    },
  },
  validators: {
    selectedClient(value) {
      if (this.type === 'old') {
        return Validator.value(value).required(this.$i18n.t('errors.lead.client.required'));
      } else {
        return Validator.value(true);
      }
    },
    name(value) {
      if (this.type === 'new') {
        return Validator.value(value).required(this.$i18n.t('errors.client.name.required'));
      } else {
        return Validator.value(true);
      }
    },
    phone(value) {
      if (this.type === 'new') {
        return Validator.value(value).required(this.$i18n.t('errors.client.phone.required'));
      } else {
        return Validator.value(true);
      }
    },
    email(value) {
      if (this.type === 'new') {
        return Validator.value(value).required(this.$i18n.t('errors.client.email.required'));
      } else {
        return Validator.value(true);
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedBank(value) {
      // if (this.type === 'new') {
      //   return Validator.value(value).required(this.$i18n.t('errors.client.selectedBank.required'));
      // } else {
      return Validator.value(true);
      // }
    },
    // eslint-disable-next-line no-unused-vars
    accountHolder(value) {
      // if (this.type === 'new') {
      //   return Validator.value(value).required(
      //     this.$i18n.t('errors.client.accountHolder.required'),
      //   );
      // } else {
      return Validator.value(true);
      // }
    },
    // eslint-disable-next-line no-unused-vars
    accountNumber(value) {
      // if (this.type === 'new') {
      //   return Validator.value(value).required(
      //     this.$i18n.t('errors.client.accountNumber.required'),
      //   );
      // } else {
      return Validator.value(true);
      // }
    },
  },
  methods: {
    getClients(query) {
      this.$store.dispatch('client/getData', {
        query: query,
      });
    },
    beforeOpen(event) {
      this.type = event.params.type;
      this.selectedClient = event.params.client;
      this.commitString = event.params.commitString;
      this.dispatchString = event.params.dispatchString;
      this.dispatchAfterString = event.params.dispatchAfterString;
      this.$store.commit('client/form/SET_FORM_TYPE', event.params.formType);
      this.initialize();
    },
    // eslint-disable-next-line no-unused-vars
    beforeClose(event) {
      this.type = null;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async initialize() {
      if (this.type === 'new') {
        await this.$store.dispatch('client/form/restoreInitialState');
        await this.$store.dispatch('client/form/fetchBaseData');
      }
    },
    chooseType(newType) {
      this.type = newType;
      this.initialize();
    },
    async submit() {
      if (await this.$validate()) {
        if (this.commitString) {
          if (this.type === 'old') {
            this.$store.commit(this.commitString, this.selectedClient);
          } else {
            this.$store.commit(this.commitString, this.$store.getters['client/form/formData']);
          }
        }
        await this.$store.dispatch(this.dispatchString);
        if (this.dispatchAfterString) {
          await this.$store.dispatch(this.dispatchAfterString);
        }
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('lead.assignClient.successMsg'),
          'success',
        );
        this.close();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
